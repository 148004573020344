import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-charger',
  templateUrl: './charger.component.html',
  styleUrls: ['./charger.component.scss']
})
export class ChargerComponent implements OnInit {

  public slideIndex = 1;

  constructor() { }

  ngOnInit() {
    this.showSlides(this.slideIndex);
  }

  // Next/previous controls
  public plusSlides(n: number) {
    this.showSlides(this.slideIndex += n);
  }

  // Thumbnail image controls
  public currentSlide(n: number) {
    this.showSlides(this.slideIndex = n);
  }

  public showSlides(n: number) {
    let i;
    const slides = document.getElementsByClassName('mySlides');
    if (n > slides.length) {this.slideIndex = 1; }
    if (n < 1) {this.slideIndex = slides.length; }
    for (i = 0; i < slides.length; i++) {
        slides[i].setAttribute('style', 'display:none;');
    }
    slides[this.slideIndex - 1].setAttribute('style', 'display:block');
  }

  public onOrder() {
    const orderBody =
      'How many do you want?%0D%0A' +
      '%0D%0AWhat is the address you would like this shipped to?%0D%0A';

      window.location.href =
      'mailto:orders@twolectronics.com?subject=I\'d Like to Order a Charger&body=' + orderBody;

  }
}
