import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'twolectronics';
  logo = 'assets/images/Logo.png';

  constructor(
    private readonly router: Router,
  ){

  }

  public onLogoClick() {
    this.router.navigate(['']);
  }
  public onHome() {
    this.router.navigate(['']);
  }
  public onAboutUs() {
    this.router.navigate(['about-us']);
  }

  public onProduct() {
    this.router.navigate(['products']);
  }

  public onBackpack() {
    this.router.navigate(['products/backpack']);
  }

  public onWatch() {
    this.router.navigate(['products/watch']);
  }

  public onCharger() {
    this.router.navigate(['products/charger']);
  }

  public onBattery() {
    this.router.navigate(['products/battery']);
  }

  public onScreenProtector() {
    this.router.navigate(['products/screen-protector']);
  }
}
