import { HomeComponent } from './components/home/home.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { Routes, RouterModule } from '@angular/router';
import { ProductsComponent } from './components/products/products.component';
import { NgModule } from '@angular/core';
import { BackpackComponent } from './components/products/backpack/backpack.component';
import { WatchComponent } from './components/products/watch/watch.component';
import { ScreenProtectorComponent } from './components/products/screen-protector/screen-protector.component';
import { BatteryComponent } from './components/products/battery/battery.component';
import { ChargerComponent } from './components/products/charger/charger.component';
const routes: Routes = [
    { path: '', component: HomeComponent},
    { path: 'about-us', component: AboutUsComponent },
    { path: 'products', component: ProductsComponent},
    { path: 'products/backpack', component: BackpackComponent },
    { path: 'products/watch', component: WatchComponent },
    { path: 'products/charger', component: ChargerComponent },
    { path: 'products/battery', component: BatteryComponent },
    { path: 'products/screen-protector', component: ScreenProtectorComponent }
  ];

  @NgModule({
    imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
    exports: [RouterModule]
  })
  export class AppRoutingModule { }

