import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})

export class AboutUsComponent implements OnInit {

  constructor(private readonly router: Router) { }

  ngOnInit() {
  }

  public onAboutUs() {
    window.location.href = 'mailto:ambassadors@twolectronics.com?subject=I\'m interested in the Ambassador Program' +
    '&body=Leave your name and email/phone number, and we\'ll get back to you as soon as we can!';
    }
}
