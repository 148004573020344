import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { ProductsComponent } from './components/products/products.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { MatToolbarModule, MatIconModule, MatMenuModule, MatExpansionModule, MatButtonToggleModule } from '@angular/material';
import { RouterModule, ROUTES } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BackpackComponent } from './components/products/backpack/backpack.component';
import { WatchComponent } from './components/products/watch/watch.component';
import { ChargerComponent } from './components/products/charger/charger.component';
import { BatteryComponent } from './components/products/battery/battery.component';
import { ScreenProtectorComponent } from './components/products/screen-protector/screen-protector.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProductsComponent,
    AboutUsComponent,
    BackpackComponent,
    WatchComponent,
    ChargerComponent,
    BatteryComponent,
    ScreenProtectorComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    MatToolbarModule,
    MatExpansionModule,
    MatButtonToggleModule,
    RouterModule,
    // Angular Modules
    BrowserModule,
    RouterModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    // Angular Material Modules
    MatToolbarModule,
    MatMenuModule,
    MatIconModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
