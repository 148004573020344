import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  constructor(private readonly router: Router, ) { }

  ngOnInit() {
    }

    public onMoreInfo() {
      window.location.href = 'mailto:info@twolectronics.com?subject=More Products?&body=What Product(s) are you looking for?%0D%0A';
    }

    public onBackpack() {
      this.router.navigate(['products/backpack']);
    }

    public onWatch() {
      this.router.navigate(['products/watch']);
    }

    public onCharger() {
      this.router.navigate(['products/charger']);
    }

    public onBattery() {
      this.router.navigate(['products/battery']);
    }

    public onScreenProtector() {
      this.router.navigate(['products/screen-protector']);
    }

    public onOrder() {
      const orderBody =
      'What Product(s) would you like to order? (For each product, list the color(s) and quantity)%0D%0A' +
      '%0D%0AWhat is the address you would like this shipped to?%0D%0A';

      window.location.href =
      'mailto:orders@twolectronics.com?subject=I\'d Like to Place an Order&body=' + orderBody;
   }
}
